import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()((theme) => ({
  container: {
    // padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  loadingContainer: {
    width: '100%',
    padding: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
  textAreaRowContainer: {
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    width: 160,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  checkboxFieldContainer: {
    paddingTop: 9,
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  inputMargin: {
    marginRight: 20,
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  section: {
    padding: '10px 20px',
  },
  divideMargin: {
    marginBottom: 10,
  },
  normalBox: {
    width: 250,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 20,
  },
  settingTable: {
    width: '50%',
  },
  notificationMessage: {
    fontSize: '1rem',
  },
}));
