import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import { useDispatch } from 'react-redux';
import { fetchTalkbotReportList, fetchUnitCodes, sendReport } from '../../../network/talkbot-report-crud';
import { TalkbotReportFilterType, TalkbotReportListParam } from '../../../types/talkbot-report-type';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { useTranslation } from 'src/app/common/hook/translation.hook';

const initialState: TalkbotReportFilterType = {
  reportMonth: new Date(),
  dateFrom: null,
  dateTo: null,
  unitCode: '',
  agentCode: '',
  managerCode: '',
};

const filterRow1 = ['reportMonth', 'dateFrom', 'dateTo'];
const filterRow2 = ['unitCode', 'agentCode', 'managerCode'];

export const useList = () => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const [initFilterState, setInitFilterState] = useState(initialState);
  const [filterState, setFilterState] = useState<TalkbotReportFilterType>(initialState);

  const { data: unicodeList, run: fetchUnitCodeList } = useRequest(() => fetchUnitCodes(dispatch), {
    manual: false,
    onSuccess: (data) => {
      setInitFilterState((prev) => ({ ...prev, unitCode: data[0].value }));
      setFilterState((prev) => ({ ...prev, unitCode: data[0].value }));
    },
  });

  const {
    data: reportListData,
    loading,
    run: fetchList,
  } = useRequest((params, dispatch) => fetchTalkbotReportList({ page: 1, limit: 20, ...params }, dispatch), {
    manual: true,
  });

  const { run: downloadReport, loading: sendReportLoading } = useRequest(
    (params, dispatch) => sendReport(params, dispatch),
    {
      manual: true,
      onSuccess: () => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              content: t('talkbot.report.send_report_success_msg'),
            },
          ]),
        );
      },
    },
  );

  useEffect(() => {
    if (initFilterState.unitCode) {
      fetchList(initFilterState, dispatch);
    }
  }, [dispatch, fetchList, initFilterState]);

  const changeFilterState = useCallback((key: keyof TalkbotReportListParam, value: any) => {
    const clearOtherFilters = (excludeKey: keyof TalkbotReportListParam, arr: string[], value: any) => {
      arr.forEach((item) => {
        if (item !== excludeKey) {
          setFilterState((prev) => ({ ...prev, [item]: value }));
        }
      });
    };

    if (filterRow1.includes(key)) {
      if (key === 'reportMonth') {
        clearOtherFilters(key, filterRow1, null);
      } else {
        setFilterState((prev) => ({ ...prev, reportMonth: null }));
      }
    }

    if (filterRow2.includes(key)) {
      clearOtherFilters(key, filterRow2, '');
    }

    setFilterState((prev) => ({ ...prev, [key]: value }));
  }, []);

  const checkFilter = useCallback(
    (filterState: TalkbotReportFilterType) => {
      // reporting date range and one search criteria of Unit Code / Reporting Manager FC Number / FC Number are needed before request
      const hasFilterRow2Value = filterRow2.some((key) => !!filterState[key as keyof TalkbotReportFilterType]);
      if (hasFilterRow2Value && (filterState.reportMonth || (filterState.dateFrom && filterState.dateTo))) {
        return true;
      }
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            content: t('talkbot.report.filter_required_msg'),
          },
        ]),
      );
      return false;
    },
    [dispatch, t],
  );

  const onReset = useCallback(() => {
    setFilterState(initFilterState);
  }, [initFilterState]);

  return {
    initFilterState,
    filterState,
    unicodeList,
    loading,
    sendReportLoading,
    reportListData,
    fetchList,
    fetchUnitCodeList,
    changeFilterState,
    onReset,
    downloadReport,
    checkFilter,
  };
};
